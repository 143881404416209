.TextField-border-radius fieldset {
    // border-radius: 0;
}

.MuiChip-deleteIcon {
    float: right !important;
}

.AutoCompleteOption {
    font-size: 13px;
    border-bottom: 1px solid "system[5]";
}

.AutoCompleteListbox {
    padding: 0;
}

.AutoCompleteText {
    font-size: 11px;
}

.AutoCompleteOption_ListItemButton {
    background-color: #ccff99;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.AutoCompleteOption_ListItemText {
    font-size: 0.75rem !important;
}

.AutoCompleteWithNotes_TextField {
    font-size: 11px !important;
    padding: 0 !important;
}

.AutoCompleteWithNotes_Icon {
    font-size: 13px !important;
    padding: 0 !important;
}

.AutoCompleteWithNotes_MultipleTags {
    font-size: 11px !important;
    padding: 0 !important;
    margin: 0 !important;
}

.AutoCompleteInput_Icon {
    font-size: 18px !important;
    padding: 0 !important;
}